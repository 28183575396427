<template>
    <div class="page">

        <Head :type="0">

            <template #title>

                <div >寄回商品</div>

            </template>

        </Head>

        <div class="main">

            <div class="static">

                    <div class="title">  请寄回商品  </div>

                    <div class="subtitle">请尽快填写快递单号，否则可能退款失败</div>
                    <div class="subtitle">请准确填写快递单号</div>

                   
            </div>

            <div class="wrap">

                    <div class="title"> 自己联系快递寄回，需填写快递单号 </div>
                       
                    <div class="address" v-if="refund!=nulls"><span>退货地址：小李宁 {{refund.refundPhone}} </span> <div>{{refund.refundAddress}}</div></div>

                    <ul>
                        <li>
                            <div class="left">物流公司</div>
                                
                           <input type="text"  placeholder="请选择快递物流公司"  v-model="info"  @input="query">

                            <img class="right" src="../../../assets/img/right_gery.png" alt=""/>

                        </li>

                        <li>

                            <div class="left">快递单号</div>

                            <input type="text"   v-model="expressNo" placeholder="请输入退货快递单号">

                        </li>

                        <li>
                            <div class="left">上传图片</div>

                             <div class="content">

                                     <van-uploader v-model="fileList" multiple :max-count="5" :after-read="afterRead"  :accept="'image/png'"/>

                            </div>

                        </li>

                    </ul>

            </div>

        </div>

        <van-popup v-model="showPicker" round position="bottom">

                <van-picker show-toolbar :columns="list"  @cancel="showPicker = false"  @confirm="onConfirm" value-key="companyName"/>
                   
        </van-popup>

        <div class="footer">

            <div class="box">

                <div class="btn" @click="submit"> 确认提交 </div>
                   
            </div>

        </div>

    </div>

</template>

<script>
import { upLoad,getVoucher,getLogisticsList , makerGetLog} from "../../../api/maker/order.js"
import{ getRefundDetail} from '../../../api/maker/order'

import Head from '../../../components/head/index.vue'
    export default {
        data(){ 

            return{ 

                expressNo:'',

                info:'',

                fileList: [],

                list:[],

                showPicker:false,

                refund:null,

                express:null
            }
        },
        created(){ 

            this.setRem()

            this.refundGoods()
        },

        methods:{ 

            afterRead(file) {
                file.status = 'uploading';
                // file.message = '上传中...';
                
                console.log(this.fileList)

                let formData = new window.FormData();

                formData.append("file", file.file);

                formData.append("fileType", "image/png");

                formData.append("dir", "material");

                upLoad(formData).then(res=>{ 

                    if(res.data.code===0){ 

                        getVoucher({url:res.data.data}).then(res=>{ 
                        file.status = 'upload';

                        file.url=res.data.data.imageDomain+'/'+res.data.data.imageRelativeUrl

                        }).catch(()=>{ 

                        file.status = 'failed';

                        })

                    }
                     console.log(this.fileList)

                }).catch(()=>{ 

                        file.status = 'failed';

                })

            },

            onConfirm(value){ 

                    this.showPicker = false;

                    this.express=value

                    this.info=this.express.companyName

            },

            query(){ 
                          clearInterval(this.timerID)
                            if(!this.info){ 
                                return
                            }
                         this.timerID= setTimeout(() => {
                               getLogisticsList({name:this.info}).then(res=>{ 
                                if(res.data.code===0){ 
                                    this.list=res.data.data
                                    if(res.data.data.length!==0){ 
                                    this.showPicker=true
                                    document.activeElement.blur();
                                    }
                                }
                            })
                          }, 500);
                           
                      },
            
            refundGoods(){ 

                        getRefundDetail(this.$route.query.id).then(res=>{ 

                            if(res.data.code===0){ 

                                this.refund=res.data.data

                            }
                            
                        })

            },
            submit(){ 

                if(this.express&&this.expressNo){ 
                    //
                }else{ 

                    this.$toast('请填写完整信息')
                }

                makerGetLog({logisticsCompanyNo:this.express.id,id:this.$route.query.id,freightBill:this.expressNo}).then(res=>{ 

                    if(res.data.code==0){ 

                        this.$toast.success('更新成功')
                        return
                    }
                    this.$toast.fail('更新失败')
                })


            }


        },

        components:{Head}
    }
</script>

<style lang="less" scoped>
    .page{ 
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-boxs;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: .373333rem;
        display: flex;
        flex-direction: column;

        .main{ 
            height: 100%;
            background-color: #f6f6f6;
            flex: 1;
            .static{ 
                background-color: white;
                border-top: 1px solid #f6f6f6;
                padding: .426667rem;
                margin-bottom: .266667rem;
                .title{ 
                    font-weight: 600;
                    font-size: .56rem;
                    color: #333;
                    margin-bottom: .133333rem;
                }
                .subtitle{ 
                    color: #999;
                    font-size: .373333rem;
                }

            }
            .wrap{ 
                background-color: white;
                padding: .426667rem;
               
                .title{ 
                    color:#333 ;
                    margin-bottom: .266667rem;
                }
                .address{ 
                    font-size: .32rem;
                    color: #999;
                    margin-bottom: .533333rem;
                    div{ 
                        margin-top: .133333rem;
                        padding-left: 1.6rem;
                    }
                }
                li{ 
                    display: flex;
                    align-items: center;
                    padding: .346667rem 0 .346667rem .4rem ;
                    
                    .left{ 
                        font-size: .453333rem;
                        border-right: 1px solid rgba(0,0,0,.1);
                        padding-right: .293333rem;
                    }
                    .right{ 
                        width:.346667rem ;
                        height: .373333rem;
                    }
                    input{ 
                        flex: 1;
                        padding-left: .266667rem;
                        font-size: .373333rem;
                    }
                    input::-webkit-input-placeholder{ 
                        font-size: .373333rem;
                        color: #ccc;
                    }
                    .content{
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: .266667rem;
              /deep/  .van-image{ 
                    width: 1.066667rem !important;
                    height: 1.066667rem !important;
                    border-radius: 10%;
                }
             /deep/  .van-uploader__upload{ 
                     width: 1.066667rem !important;
                    height: 1.066667rem !important;
                    border-radius: 10%;

                }
            }
            img{
                width: .4rem;
                height: .666667rem;
            }
                }
            }
        }

        .footer{ 
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);

            .box{ 
                padding: .266667rem;
                .btn{ 
                    border-radius: .48rem;
                    height: .96rem;
                    width: 100%;
                    background-image: linear-gradient(to right ,#ffb97f 80%,#ffa07f );
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: .373333rem;
                }
            }
        }
    }
</style>